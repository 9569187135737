import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import Center1Column from "../../../layout/Page/Center/1column";
import { ArrowDownIcon, ArrowsExpandIcon, ArrowUpIcon, PencilAltIcon, PlusCircleIcon } from "@heroicons/react/outline";
import { addFormTypes, getCurentUser, getFormTypes, getUserFrom, moveFormsTypes, patchFormTypes, removeFormTypes } from "../../../utils/requests/cosmo";
import Card from "../../../components/Card/Card";
import SwitchFake from "../../../components/Fake/Switch";
import Structure from "../../../components/Forms/Structure";
import TextArea from "../../../components/Inputs/TextArea";
import FullLoader from "../../../components/Animation/FullLoader";
import { TrashIcon } from "@heroicons/react/solid";
import DeleteModal from "../../../components/Popup/deleteModal";
import ErrorModal from "../../../components/Popup/errorModal";
import { useParams } from "react-router";


function FormsTypes({ t }) {
    const [loading, setLoading] = useState(true);
    const [formTypes, setFormTypes] = useState([]);
    const [newForm, setNew] = useState(false);
    const [authent, setAuthent] = useState(false);
    const [canCreateNewDeal, setCanCreateNewDeal] = useState(false);
    const [visibleIfNoDeal, setVisibleIfNoDeal] = useState(false);
    const [superUser, setSuperUser] = useState(false);
    const [infoMoveSave, setInfoMoveSave] = useState(null);
    const [selectedSchemas, setSelectedSchemas] = useState([]);
    const [modifSchema, setModifSchema] = useState(-1);
    const [removeForm, setRemoveForm] = useState(null);
    const [errorModal, setErrorModal] = useState(null);
    const [key, setPartitionKey] = useState(null);
    let { partitionKey } = useParams();

    useEffect(() => {
        if (partitionKey) {
            setPartitionKey(partitionKey);
            getFormTypes(partitionKey).then(res => {
                setPartitionKey(partitionKey);
                setFormTypes(res);
                setLoading(false);
            })
            getUserFrom(partitionKey).then(curentUser => {
                setAuthent(false);
                setCanCreateNewDeal(false);
                setVisibleIfNoDeal(false);
                setSuperUser(curentUser.superUser);
            })
        } else {
            getCurentUser().then(curentUser => {
                setLoading(true);
                setAuthent(false);
                setCanCreateNewDeal(false);
                setVisibleIfNoDeal(false);
                getFormTypes(curentUser.partitionKey).then(res => {
                    setPartitionKey(curentUser.partitionKey);
                    setFormTypes(res);
                    setLoading(false);
                })
                setSuperUser(curentUser.superUser);
            })
        }
    }, []);

    function formSave(e) {
        setLoading(true);
        e.preventDefault();
        // console.log(e);
        let output = {};
        for(let t = 0; e.target[t]; t++) {
            if (e.target[t].name && e.target[t].type !== "checkbox") {
                output[e.target[t].name] = e.target[t].value;
            }
        }
        output["authent"] = authent;
        output["can_create_new_deal"] = canCreateNewDeal;
        output["visible_if_no_deal"] = visibleIfNoDeal;
        output["order"] = formTypes.length;
        // console.log(output);
        addFormTypes(JSON.stringify(output), key).then(res => {
            // console.log(res);
            if (res) {
                setAuthent(false);
                setCanCreateNewDeal(false);
                setVisibleIfNoDeal(false);
                setNew(false);
                setFormTypes([...formTypes, res]);
            }
            setLoading(false);
        })
    }

    async function cancelMove() {
        setFormTypes(infoMoveSave)
        setInfoMoveSave(null)
    }

    async function saveMove() {
        //TODO
        setLoading(true);
        moveFormsTypes(JSON.stringify(formTypes), key).then(res => {
            if (res && res === "moved") {
                setLoading(false);
            } else {
                getFormTypes(key).then(res => {
                    setFormTypes(res);
                    setLoading(false);
                })
            }
        })
        setInfoMoveSave(null)
    }

    async function InfoMoveDir(direction, position) {
        if (infoMoveSave === null) {
            setInfoMoveSave(JSON.parse(JSON.stringify(formTypes)))
        }
        var localSelected = JSON.parse(JSON.stringify(formTypes))
        if (direction === "up" && position > 0) {
            localSelected[position] = localSelected.splice(position - 1, 1, localSelected[position])[0];
            localSelected[position].order = position;
            localSelected[position - 1].order = position - 1;
        } else if (direction === "down" && position < formTypes.length - 1) {
            localSelected[position] = localSelected.splice(position + 1, 1, localSelected[position])[0];
            localSelected[position].order = position;
            localSelected[position + 1].order = position + 1;
        }
        setFormTypes(localSelected)
    }

    async function SaveModifSchema(e) {
        setLoading(true);
        e.preventDefault();
        let output = {};
        for(let t = 0; e.target[t]; t++) {
            if (e.target[t].name && e.target[t].type !== "checkbox") {
                output[e.target[t].name] = e.target[t].value;
            }
        }
        output["authent"] = authent;
        output["can_create_new_deal"] = canCreateNewDeal;
        output["visible_if_no_deal"] = visibleIfNoDeal;
        output["order"] = formTypes[modifSchema].order;
        output["id"] = formTypes[modifSchema].id;
        // console.log(output);
        //TODO
        patchFormTypes(JSON.stringify(output), key).then(res => {
            if (res) {
                setAuthent(false);
                setCanCreateNewDeal(false);
                setVisibleIfNoDeal(false);
                setModifSchema(-1);
                getFormTypes(key).then(res => {
                    setFormTypes(res);
                    setLoading(false);
                })
            } else {
                setLoading(false);
            }
        })
    }

    return <Center1Column>
        {/* Main 3 column grid */}
        {infoMoveSave ? <div className="flex flex-row gap-2">
                            <button className="w-full flex items-center justify-center px-5 border border-transparent text-base font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500" onClick={saveMove} >{t("Save")}</button>
                            <button className="w-full flex items-center justify-center px-5 border border-transparent text-base font-medium rounded-md text-white bg-slate-400 hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-300" onClick={cancelMove} >{t("Cancel")}</button>
                        </div> :
                        <></>
}
        <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-1 lg:gap-8">
                    {formTypes.map((e, i) =>
                        <Card key={i} title={e.name}>
                            {modifSchema !== i ? <div className="flex flex-col gap-y-4">
                                <div className="grid grid-cols-5">
                                    <div>{t("title")}</div>
                                    <div className="border col-span-4">{e.title}</div>
                                </div>
                                <div className="grid grid-cols-5">
                                    <div>{t("description")}</div>
                                    <div className="border col-span-4">{e.description}</div>
                                </div>
                                <div className="grid grid-cols-5">
                                    <div>{t("authent")}</div>
                                    <div className="col-span-4 pl-4">
                                        <SwitchFake value={e.authent} />
                                    </div>
                                    {/* <div className="border col-span-4">{e.authent}</div> */}
                                </div>
                                <div className="grid grid-cols-5">
                                    <div>{t("can_create_new_deal")}</div>
                                    <div className="col-span-4 pl-4">
                                        <SwitchFake value={e.can_create_new_deal} />
                                    </div>
                                    {/* <div className="border col-span-4">{e.can_create_new_deal}</div> */}
                                </div>
                                <div className="grid grid-cols-5">
                                    <div>{t("visible_if_no_deal")}</div>
                                    <div className="col-span-4 pl-4">
                                        <SwitchFake value={e.visible_if_no_deal} />
                                    </div>
                                    {/* <div className="border col-span-4">{e.visible_if_no_deal}</div> */}
                                </div>
                                {/* <div className="grid grid-cols-5">
                                    <div>{t("image")}</div>
                                    <div className="border col-span-4">{e.image}</div>
                                </div>
                                <div className="grid grid-cols-5">
                                    <div>{t("logo")}</div>
                                    <div className="border col-span-4">{e.logo}</div>
                                </div> */}
                            </div>:
                            <form onSubmit={SaveModifSchema}>
                                <div className="flex flex-col gap-y-4">
                                    <Structure id="name" title={t("Name")} component={() => <input type="text" name={"name"} title="name" required className="border block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3" defaultValue={e.name} />} />
                                    <Structure id="title" title={t("Title")} component={() => <input type="text" name={"title"} title="title" required className="border block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3" defaultValue={e.title} />} />
                                    <Structure id="description" title={t("Description")} component={() => <TextArea name="description" title="description" className="border block w-full sm:text-sm border-gray-300 rounded-md px-3" defaultValue={e.description} />} />
                                    <Structure id="authent" title={t("Authent")} component={() => <input type="checkbox" onChange={() => setAuthent(!authent)} title="authent" name="authent" className="border block w-full sm:text-sm border-gray-300 rounded-md h-7 w-7 px-3 cursor-pointer" defaultChecked={e.authent} />} />
                                    <Structure id="can_create_new_deal" title={t("Can create new deal")} component={() => <input type="checkbox" onChange={() => setCanCreateNewDeal(!canCreateNewDeal)} title="can_create_new_deal" name="can_create_new_deal" className="border block w-full sm:text-sm border-gray-300 rounded-md h-7 w-7 px-3 cursor-pointer" defaultChecked={e.can_create_new_deal} />} />
                                    <Structure id="visible_if_no_deal" title={t("Visible if no deal")} component={() => <input type="checkbox" onChange={() => setVisibleIfNoDeal(!canCreateNewDeal)} title="visible_if_no_deal" name="visible_if_no_deal" className="border block w-full sm:text-sm border-gray-300 rounded-md h-7 w-7 px-3 cursor-pointer" defaultChecked={e.visible_if_no_deal} />} />
                                    {/* <Structure id="image" title={t("Image")} component={() => <input type="text" title="image" name="image" className="border block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3"/>} />
                                    <Structure id="logo" title={t("Logo")} component={() => <input type="text" title="logo" name="logo" className="border block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3"/>} /> */}
                                </div>
                                <div className="flex justify-end gap-x-4">
                                    <button type="submit" className="bg-primary-500 text-white rounded-lg px-4 py-2 hover:bg-primary-600">Save</button>
                                    <button type="button" onClick={e => setModifSchema(-1)} className="bg-gray-300 text-gray-700 rounded-lg px-4 py-2 hover:bg-gray-400">Cancel</button>
                                </div>
                            </form>}
                            <div className="flex flex-row gap-2">
                            {modifSchema === -1 ? <>
                                            <ArrowUpIcon className="h-7 w-7 text-primary-400 hover:text-primary-600 focus:outline-none focus:text-white hover:font-bold cursor-pointer" onClick={() => InfoMoveDir("up", i)} />
                                            <ArrowDownIcon className="h-7 w-7 text-primary-400 hover:text-primary-600 focus:outline-none focus:text-white hover:font-bold cursor-pointer" onClick={() => InfoMoveDir("down", i)} />
                                        </> : <></>
}
{!infoMoveSave && modifSchema === -1 ?
                                    <button
                                        type="button"
                                        className="flex-shrink-0 my-auto h-7 w-7 rounded-full inline-flex items-center justify-center text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:outline-none focus:bg-blue-500 focus:text-white"
                                        onClick={() => {setModifSchema(i); setAuthent(e.authent); setCanCreateNewDeal(e.can_create_new_deal); setVisibleIfNoDeal(e.visible_if_no_deal)}}
                                    >
                                        <span className="sr-only">Modif property</span>
                                         <PencilAltIcon className="h-7 w-7" />
                                    </button> : <></>}
                                    {!infoMoveSave && modifSchema === -1 ?
                                    <button
                                        type="button"
                                        className="flex-shrink-0 my-auto h-7 w-7 rounded-full inline-flex items-center justify-center text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:outline-none focus:bg-blue-500 focus:text-white"
                                        onClick={() => {setRemoveForm(e)}}
                                    >
                                        <span className="sr-only">Remove property</span>
                                         <TrashIcon className="h-7 w-7" />
                                    </button> : <></>}
                                    {modifSchema === i ? <div className="flex flex-row gap-2">
                            {/* <button className="w-full flex items-center justify-center px-5 border border-transparent text-base font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500" onClick={SaveModifSchema} >{t("Save")}</button>
                            <button className="w-full flex items-center justify-center px-5 border border-transparent text-base font-medium rounded-md text-white bg-slate-400 hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-300" onClick={() => {setModifSchema(-1)}} >{t("Cancel")}</button> */}
                        </div> : <></>}
</div>
                        </Card>
                    )}
                </div>
                <div className="mt-6 w-full">{
                    newForm ?
                        <Card key={"newFormType"} title={"New Form Type"} className="w-full">
                            <form onSubmit={formSave}>
                                <div className="flex flex-col gap-y-4">
                                    <Structure id="name" title={t("Name")} component={() => <input type="text" name={"name"} title="name" required className="border block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3"/>} />
                                    <Structure id="title" title={t("Title")} component={() => <input type="text" name={"title"} title="title" required className="border block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3"/>} />
                                    <Structure id="description" title={t("Description")} component={() => <TextArea name="description" title="description" className="border block w-full sm:text-sm border-gray-300 rounded-md px-3"/>} />
                                    <Structure id="authent" title={t("Authent")} component={() => <input type="checkbox" onChange={() => setAuthent(!authent)} title="authent" name="authent" className="border block w-full sm:text-sm border-gray-300 rounded-md h-7 w-7 px-3 cursor-pointer"/>} />
                                    <Structure id="can_create_new_deal" title={t("Can create new deal")} component={() => <input type="checkbox" onChange={() => setCanCreateNewDeal(!canCreateNewDeal)} title="can_create_new_deal" name="can_create_new_deal" className="border block w-full sm:text-sm border-gray-300 rounded-md h-7 w-7 px-3 cursor-pointer"/>} />
                                    <Structure id="visible_if_no_deal" title={t("Visible if no deal")} component={() => <input type="checkbox" onChange={() => setVisibleIfNoDeal(!canCreateNewDeal)} title="visible_if_no_deal" name="visible_if_no_deal" className="border block w-full sm:text-sm border-gray-300 rounded-md h-7 w-7 px-3 cursor-pointer"/>} />
                                    {/* <Structure id="image" title={t("Image")} component={() => <input type="text" title="image" name="image" className="border block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3"/>} />
                                    <Structure id="logo" title={t("Logo")} component={() => <input type="text" title="logo" name="logo" className="border block w-full sm:text-sm border-gray-300 rounded-md h-8 px-3"/>} /> */}
                                </div>
                                <div className="flex justify-end gap-x-4">
                                    <button type="submit" className="bg-primary-500 text-white rounded-lg px-4 py-2 hover:bg-primary-600">Save</button>
                                    <button type="button" onClick={e => setNew(false)} className="bg-gray-300 text-gray-700 rounded-lg px-4 py-2 hover:bg-gray-400">Cancel</button>
                                </div>
                            </form>
                        </Card>
                        :
                        <div className="justify-self-center">
                            {(superUser && modifSchema === -1) ? <button onClick={e => setNew(true)}><PlusCircleIcon className="h-10 w-10 text-primary-500 hover:text-primary-600 cursor-pointer" /></button> : <button onClick={() => {}}><PlusCircleIcon className="h-10 w-10 text-slate-300 cursor-not-allowed" /></button>}
                        </div>
                }
                </div>
                {errorModal && <ErrorModal tilte={"This type is used in these forms:"} detail={errorModal.join(", ")} onclick={() => {setErrorModal(null)}} />}
                {removeForm && <DeleteModal title={"Do you want to remove " + removeForm.name} accept={() => {setLoading(true);removeFormTypes(removeForm.id, key).then(e => {
                    if (e !== "ok") {
                        setErrorModal(e)
                        setLoading(false);
                    } else {
                        setRemoveForm(null);
                        getFormTypes(key).then(res => {
                            setFormTypes(res);
                            setLoading(false);
                        })
                    }
                })}} cancel={() => {setRemoveForm(null)}} detail={"This type wont be removed if it is used in forms"} />}
                {loading && <FullLoader/>}
    </Center1Column>
}

export default withTranslation()(FormsTypes);