// import all pages 
import Home from "../../pages/Home";
import Form from "../../pages/Form";
import Settings from "../../pages/Settings";
import { HomeIcon, CodeIcon } from '@heroicons/react/outline'


const routes = [
    {
        path: "/",
        module : "home",
        element: <Home/>,
        exact : true,
        isMenuIcon : false,
        logo : HomeIcon,
        name : "Home"
    },
    {
        path: "/:partitionKey",
        module : "home",
        element: <Home/>,
        exact : true,
        isMenuIcon : false,
        logo : HomeIcon,
        name : "Home"
    },
    {
        path: "/settings/:type",
        module : "settings",
        element: <Settings/>,
        exact : true,
        isMenuIcon : false,
        name : "Settings"
    },
    {
        path: "/settings/:type/:partitionKey",
        module : "settings",
        element: <Settings/>,
        exact : true,
        isMenuIcon : false,
        name : "Settings"
    },
    {
        path: "/:id/:onglet/:partitionKey",
        module : "form",
        element: <Form/>,
        exact : true,
        isMenuIcon : false,
        logo : CodeIcon,
        name : "Form"
    },
    {
        path: "/:id/:onglet/:partitionKey/:formType",
        module : "form",
        element: <Form/>,
        exact : true,
        isMenuIcon : false,
        logo : CodeIcon,
        name : "Form"
    },
]

export default routes;