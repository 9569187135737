import { InformationCircleIcon, SearchIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { v4 as uuidv4 } from 'uuid';
import Card from "../../../../components/Card/Card";
import { SIDEBAR_ITEM } from "../../../../components/DragNDrop/constants";
import SideBarItem from "../../../../components/DragNDrop/SideBarItem";
import TrashDropZone from "../../../../components/DragNDrop/TrashDropZone";
import { getStripePrices, getStripeProducts } from "../../../../utils/requests/stripe";
import SearchBarData from "./SearchBarData";

function capitalize(str) {
    str = str.charAt(0).toUpperCase() + str.slice(1);
    return str
}

function haveChildren(group, items) {
    if (items && items.length && group.status) {
        var children = items.filter(i => i.component.data.groupName === group.name)
        return children.length > 0 ? children : false;
    } else {
        return false;
    }
}

function DataList({ t, layout, handleDropToTrashBin, candidature, components, payment }) {
    const [items, setItems] = useState([]);
    const [group, setGroup] = useState(null);
    const [showSearch, setShowSearch] = useState(false);
    const [filteredItems, setFilteredItems] = useState([]);
    const [stripeProducts, setStripePoducts] = useState(null);
    const [curentSearch, setCurentSearch] = useState([]);

    useEffect(() => {
        if (payment && payment?.types?.includes("stripe")) {
            getStripeProducts().then(products => {
                getStripePrices().then(prices => {
                    if (products && prices) {
                        let productList = [];
                        products.forEach(prod => {
                            prices.forEach(price => {
                                if (prod.id === price.product) {
                                    productList.push({ value: price.id, label: prod.name })
                                }
                            })
                        })
                        setStripePoducts(productList);
                    }
                })
            })
        }
    }, [])

    //change here ↓ for more customs input or more data in components
    function sidebarGen() {
        if (candidature.Data) {
            let localGroup = [{ name: t("custom tools"), status: true }];
            // if (candidature.Variables && candidature.Variables.length) {
            //     localGroup.push({ name: t("custom variables"), status: true })
            // }
            let dataUsed = [];
            // Object.values(components).forEach(val => {
            //     dataUsed.push(val.data.name)
            // })
            if (dataUsed.length === 0 && candidature.Step.length) {
                candidature.Step.forEach(step => {
                    if (step.questions.length) {
                        step.questions.forEach(question => {
                            if (question.name !== null) {
                                dataUsed.push(question.name);
                            }
                        })
                    }
                })
            }
            if (typeof candidature.Data === "string") {
                candidature.Data = JSON.parse(candidature.Data);
            }
            let data = candidature.Data.filter(d => !dataUsed.find(du => du === d.name));
            data.forEach(e => { if (e.realGroupName) { e.groupName = e.realGroupName } })
            // data = candidature.Data
            let usedData = candidature.Data.filter(d => dataUsed.find(du => du === d.name));
            [...new Set(data.map(e => e.groupName))].forEach(a => localGroup.push({ name: a, status: true })) //problem
            localGroup.push({ name: t("read only"), status: true })
            setGroup(localGroup);
            var itemsLocal = [];
            data.forEach(e => {
                let itemTmp = { type: SIDEBAR_ITEM, component: {} };
                itemTmp.id = uuidv4();
                itemTmp.component.type = e.label;
                itemTmp.component.content = e.label;
                e.description = "";
                e.tooltip = "";
                e.conditional = false;
                itemTmp.component.data = e;
                itemsLocal.push(itemTmp);
            })
            usedData.forEach(e => {
                let itemTmp = { type: SIDEBAR_ITEM, component: {} };
                itemTmp.id = uuidv4();
                itemTmp.component.type = e.label;
                itemTmp.component.content = e.label;
                e.description = "";
                e.tooltip = "";
                e.conditional = false;
                if (!e.realGroupName) {
                    e.realGroupName = e.groupName
                }
                e.groupName = t("read only")
                itemTmp.component.data = e;
                itemsLocal.push(itemTmp);
            })
            itemsLocal.push({ type: SIDEBAR_ITEM, component: { content: "Title", data: { groupName: t("custom tools"), label: t("Title"), fieldType: "custom input", data: "", placeholder: "", name: "Title" }, type: "Title" }, id: uuidv4() })
            itemsLocal.push({ type: SIDEBAR_ITEM, component: { content: "Description", data: { groupName: t("custom tools"), label: t("Description"), fieldType: "custom input", data: "", placeholder: "", name: "Description" }, type: "Description" }, id: uuidv4() })
            itemsLocal.push({ type: SIDEBAR_ITEM, component: { content: "Image", data: { groupName: t("custom tools"), label: t("Image"), fieldType: "custom input", data: "", placeholder: "url", name: "Image" }, type: "Image" }, id: uuidv4() })
            itemsLocal.push({ type: SIDEBAR_ITEM, component: { content: "Video", data: { groupName: t("custom tools"), label: t("Video"), fieldType: "custom input", data: "", placeholder: "video url", name: "Video" }, type: "Video" }, id: uuidv4() })
            itemsLocal.push({ type: SIDEBAR_ITEM, component: { content: "Address", data: { groupName: t("custom tools"), label: t("Address"), fieldType: "custom input", options: [{ "label": "Address1", "value": null, "displayOrder": 0, "hidden": true }, { "label": "Address2", "value": null, "displayOrder": 1, "hidden": true }, { "label": "City", "value": null, "displayOrder": 2, "hidden": true }, { "label": "Zipcode", "value": null, "displayOrder": 3, "hidden": true }, { "label": "Country", "value": null, "displayOrder": 4, "hidden": true }] , data: "", placeholder: "adress", name: "Address" }, type: "Address" }, id: uuidv4() })
            itemsLocal.push({ type: SIDEBAR_ITEM, component: { content: "Empty", data: { groupName: t("custom tools"), label: t("Recap"), fieldType: "custom input", name: "Recap" }, type: "Recap" }, id: uuidv4() })
            itemsLocal.push({ type: SIDEBAR_ITEM, component: { content: "Redirect", data: { groupName: t("custom tools"), label: t("Redirect"), fieldType: "custom input", name: "Redirect", placeholder: "url", data: "" }, type: "Redirect" }, id: uuidv4() })
            if (payment && payment?.types?.includes("stripe")) {
                itemsLocal.push({ type: SIDEBAR_ITEM, component: { content: "Stripe", data: { groupName: t("custom tools"), label: t("Stripe"), fieldType: "custom input", options: stripeProducts, value: 0, data: "", placeholder: "default price", name: "Stripe" }, type: "Stripe" }, id: uuidv4() })
            }
            // console.log(payment)
            if (payment && payment?.types?.includes("paybox")) {
                itemsLocal.push({ type: SIDEBAR_ITEM, component: { content: "Paybox", data: { groupName: t("custom tools"), label: t("Paybox"), fieldType: "custom input", options: [{ "displayOrder": 3, "hidden": true, "label": "PBX_TOTAL", "value": "" }, { "displayOrder": 4, "hidden": true, "label": "PBX_DEVISE", "value": "" }], value: null, data: "", placeholder: "default price", name: "Paybox", required: true }, type: "Paybox" }, id: uuidv4() })
            }
            itemsLocal.push({ type: SIDEBAR_ITEM, component: { content: "Logic", data: { groupName: t("custom tools"), label: t("Logic block"), fieldType: "custom input", name: "Logic", conditional: true }, type: "Logic" }, id: uuidv4() })
            // candidature?.Variables?.forEach(variable => {
            //     itemsLocal.push({ type: SIDEBAR_ITEM, component: { content: "Variable", data: { groupName: t("custom variables"), label: variable.name, name: variable.id, value: variable.value, type: variable.fieldType, fieldType: "Variable", readOnly: true, hidden: true }, type: "Variable" }, id: uuidv4() })
            // })
            setItems(itemsLocal);
        } else {
            setItems([]);
            candidature.Data = [];
        }
    }

    useEffect(() => {
        sidebarGen();
    }, [candidature, stripeProducts, t, components, payment]);

    function trashPrefunction(dropZone, item) {
        if (components[item.id]?.data?.controlled) {
            components[components[item.id]?.data?.controlled].data.options.forEach((opt, i) => {
                if (opt.value === item.id) {
                    components[components[item.id].data.controlled].data.options[i].value = ""
                }
            })
        }
        if (components[item.id]?.type === "Address" && components[item.id]?.data?.fieldType === "custom input") {
            components[item.id].data.options.forEach((opt) => {
                if (opt.value && components[opt.value]) {
                    components[opt.value].data.controlled = ""
                }
            })
        }
        handleDropToTrashBin(dropZone, item)
        const componentKeys = Object.keys(components);
        componentKeys.forEach(comp => {//conditionalList remove non existant sub TODO check si c'est ca le fameu problème de sup
            if (components[comp].data.conditionalList) {
                console.log("trash cleanup 1")
                components[comp].data.conditionalList.forEach((cond, y) => {
                    cond.subs = cond.subs.filter(sub => componentKeys.includes(sub));
                    if (cond.subs.length === 0) {
                        components[comp].data.conditionalList.splice(y, 1)
                    }
                })
            }
            if (components[comp].data.sub && !componentKeys.includes(components[comp].data.sub)) {//remove sub for non existant cond
                console.log("trash cleanup 2")
                components[comp].data.sub = null
            }
        })
        sidebarGen()
    }

    useEffect(() => {
        if (curentSearch && curentSearch.length) {
            var listOutput = []
            var searchNames = []
            curentSearch.forEach(sear => {
                searchNames.push(sear.component.content)
            })
            items.forEach(item => {
                if (searchNames.includes(item.component.content)) {
                    listOutput.push(item)
                }
            })
            setFilteredItems(listOutput)
        } else {
            setFilteredItems(items)
        }
    }, [items]);

    return <TrashDropZone style={{ maxHeight: "inherit" }}
        data={{ layout }}
        onDrop={trashPrefunction} child={
            <Card noPadding>
                <div className="p-4 flex justify-between w-full">
                    <div className="flex">
                        <h2 className="text-base font-medium text-gray-900 text-2xl">{t("Data")}</h2>

                        <div id="tooltipDataTitle" data-tooltip-id="DataListTooltip" data-tooltip-content={t("Ici, vous retrouverez la donnée que vous avez sélectionnée dans l'étape précédente. Veuillez utiliser tous les champs sélectionnés afin de valider le programme.")} className="h-fit w-fit">
                            <InformationCircleIcon className="ml-1 mt-2 h-4 text-gray-500" />
                        </div>
                        <Tooltip id="DataListTooltip" effect="solid" place="top" varient="info" />
                    </div>
                    <div onClick={() => { setShowSearch(s => !s); setCurentSearch([]); sidebarGen() }}><SearchIcon className="h-5 w-5 hover:text-blue-300 cursor-pointer mt-2" aria-hidden="true" /></div>
                </div>
                {showSearch && <SearchBarData data={items ? JSON.parse(JSON.stringify(items)) : []} handleOnChange={(filteredData) => { setFilteredItems(filteredData); setCurentSearch(filteredData)}} />}
                <div className="overflow-y-auto h-fit">
                    {group && group.length ? group.filter(g => haveChildren(g, filteredItems)).map((g, i) =>
                        <div key={i} className="mb-2 border-t p-4">
                            <div>
                                <legend onClick={() => { g.status = !(g.status); }} className="text-base font-medium text-gray-900">{capitalize(g.name.replace(/_/g, " "))}</legend>
                                <div className="my-2">
                                    {haveChildren(g, filteredItems).map((sideBarItem) => (
                                        <SideBarItem key={sideBarItem.id} data={sideBarItem} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    ) : <div className="p-4">{t("Go to the Data page to fill your questionnaire")}</div>}
                </div>
            </Card>
        }>
    </TrashDropZone >
}

export default withTranslation()(DataList);