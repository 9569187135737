import { getAccessToken } from "../../auth/verifyAuth";

async function register(email, name, surname, partitionKey) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/register/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: JSON.stringify({ email: email, name: name, surname: surname })
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function deleteUser(email, partitionKey) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/removeUser/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: email
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function updateRights(listUser, partitionKey) {
    const accessToken = await getAccessToken();

    if (listUser.length) {
        return fetch(window.location.origin + "/api/v1.0/cosmos/updateRights/" + partitionKey, {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "my-Authorization": `Bearer ${accessToken}`
            },
            body: JSON.stringify(listUser)
        }).then(res => {
            if (res.ok) {
                return res.json();
            }
        }).then(res => {
            return res;
        })
    }
}

async function updateDefaultRights(id, rights, partitionKey) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/updateDefaultRights/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: id+"&"+JSON.stringify(rights)
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function getUsers(partitionKey) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/getUsers/" + partitionKey, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function getCurentUser() {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/getCurentUser", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return JSON.parse(res);
    })
}

async function getUserFrom(partitionKey) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/getUserFrom/" + partitionKey, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return JSON.parse(res);
    })
}

async function tenantData(partitionKey) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/tenantData/" + partitionKey, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        if (typeof (res) === typeof ("string")) {
            res = JSON.parse(res)
        }
        return res;
    })
}

async function tenantLongData(partitionKey) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/tenantLongData/" + partitionKey, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        if (typeof (res) === typeof ("string")) {
            res = JSON.parse(res)
        }
        return res;
    })
}

async function newForm(partitionKey) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/CreateForm/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: partitionKey
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return JSON.parse(res);
    })
}

async function updateForm(form, partitionKey) {
    const accessToken = await getAccessToken();
    // let formCpy = form;
    // formCpy.Data = JSON.stringify(formCpy.Data);
    // formCpy.Step.forEach((ee, ii) => {
    //     if (typeof (ee.questions) == "string") {
    //         formCpy.Step[ii].questions = ee.questions
    //     } else {
    //         formCpy.Step[ii].questions = JSON.stringify(ee.questions)
    //     }
    // })
    const formStr = JSON.stringify(form);

    return fetch(window.location.origin + "/api/v1.0/cosmos/UpdateForm/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: formStr
    }).then(res => {
        if (res.ok) {
            return form;
        }
    })
}

async function updateInfoForm(form, partitionKey) {
    const accessToken = await getAccessToken();
    // let formCpy = form;
    // formCpy.Data = JSON.stringify(formCpy.Data);
    // formCpy.Step.forEach((ee, ii) => {
    //     if (typeof (ee.questions) == "string") {
    //         formCpy.Step[ii].questions = ee.questions
    //     } else {
    //         formCpy.Step[ii].questions = JSON.stringify(ee.questions)
    //     }
    // })
    const formStr = JSON.stringify(form);

    return fetch(window.location.origin + "/api/v1.0/cosmos/UpdateInfoForm/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: formStr
    }).then(res => {
        if (res.ok) {
            return form;
        }
    })
}

async function updateDataForm(form, partitionKey) {
    const accessToken = await getAccessToken();
    // let formCpy = form;
    // formCpy.Data = JSON.stringify(form.Data);
    // formCpy.Step.forEach((ee, ii) => {
    //     if (typeof (ee.questions) == "string") {
    //         formCpy.Step[ii].questions = ee.questions
    //     } else {
    //         formCpy.Step[ii].questions = JSON.stringify(ee.questions)
    //     }
    // })
    const formStr = JSON.stringify(form);

    return fetch(window.location.origin + "/api/v1.0/cosmos/UpdateDataForm/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: formStr
    })
}

async function updateDesignerSteps(form, step, partitionKey) {
    const accessToken = await getAccessToken();

    let formCpy = JSON.parse(JSON.stringify(form));
    formCpy.Data = []
    formCpy.Variables = null
    formCpy.Parameters = []
    formCpy.Pipeline = null
    formCpy.ActivationDateTime = null

    // const formStr = JSON.stringify(form);
    const formStr = JSON.stringify(formCpy);

    return fetch(window.location.origin + "/api/v1.0/cosmos/UpdateDesignerSteps/" + step + "/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: formStr
    })
}

async function moveDesignerSteps(oldPosition, currPos, formId, partitionKey) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/MoveDesignerSteps/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: oldPosition + ":" + currPos + ":" + formId
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        if (res) {
            return JSON.parse(res);
        }
    })
}

async function updateFormStatus(form, partitionKey) {
    const accessToken = await getAccessToken();
    const formStr = JSON.stringify(form);

    return fetch(window.location.origin + "/api/v1.0/cosmos/UpdateFormStatus/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: formStr
    })
}

async function formData(id, partitionKey) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/GetForm/" + id + "/" + partitionKey, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        let jsonRes = JSON.parse(res)
        if (typeof (jsonRes.Data) == "string")
            jsonRes.Data = JSON.parse(jsonRes.Data)
        if (typeof (jsonRes.Step[0].questions) == "string") {
            jsonRes.Step.forEach((ee, ii) => {
                jsonRes.Step[ii].questions = JSON.parse(ee.questions)
            })
        }
        return jsonRes;
    })
}

async function updateTheme(theme, id, partitionKey) {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/SetTheme/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: (JSON.stringify(theme) + "&" + id)
    }).then(res => {
        if (res.ok) {
            return true;
        } else {
            throw new Error();
        }
    })
}

async function getHubspotKey(partitionKey) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/GetHubspotKey/" + partitionKey, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function getHubspotKeyScopes(partitionKey) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/GetHubspotKeyScopes/" + partitionKey, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        if (typeof (res) === typeof ("string")) {
            return JSON.parse(res);
        }
        return res;
    })
}

async function getHubspotKeyFO(partitionKey) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/GetHubspotKeyFO/" + partitionKey, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        if (typeof (res) === typeof ("string")) {
            return JSON.parse(res);
        }
        return res;
    })
}

async function getHubspotKeyScopesFO(partitionKey) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/GetHubspotKeyScopesFO/" + partitionKey, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return JSON.parse(res);
    })
}

async function updateHubspotKey(hubsspotKey, id, partitionKey) {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/SetHubspotKey/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: (hubsspotKey + "&" + id)
    }).then(res => {
        if (res.ok) {
            return true;
        } else {
            throw new Error();
        }
    })
}

async function updateHubspotKeyFO(hubsspotKey, id, partitionKey) {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/SetHubspotKeyFO/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: (hubsspotKey + "&" + id)
    }).then(res => {
        if (res.ok) {
            return true;
        } else {
            throw new Error();
        }
    })
}

async function updateHubspotSkeleton(id) {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/skeleton", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: (id)
    }).then(res => {
        if (res.ok) {
            return true;
        } else {
            throw new Error();
        }
    })
}

async function updateHubspotSkeletonById(id, partitionKey) {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/skeletonById/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: (id)
    }).then(res => {
        if (res.ok) {
            return true;
        } else {
            throw new Error();
        }
    })
}

async function updateHubspotSkeletonForced() {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/skeletonForced", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return true;
        } else {
            throw new Error();
        }
    })
}

async function updateHubspotPipeline(id, partitionKey) {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/pipeline/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: (id)
    }).then(res => {
        if (res.ok) {
            return true;
        } else {
            throw new Error();
        }
    })
}

async function updateInformationList(infoList, partitionKey) {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/informationList/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: (JSON.stringify(infoList))
    }).then(res => {
        if (res.ok) {
            return true;
        } else {
            throw new Error();
        }
    })
}

async function updateInformationListGet(partitionKey) {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/informationList/" + partitionKey, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return true;
        } else {
            throw new Error();
        }
    })
}

async function getHubspotSkeleton(partitionKey) {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/getSkeleton/" + partitionKey, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return JSON.parse(res);
    })
}

async function GenerateLightForms(partitionKey) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/GenerateLightForms/" + partitionKey, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function getPaymentKey(position, partitionKey) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/GetPaymentKey/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: (position)
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function updatePaymentKey(obj, position, partitionKey) {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/SetPaymentKey/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: (JSON.stringify(obj) + "&" + position)
    }).then(res => {
        if (res.ok) {
            return true;
        } else {
            throw new Error();
        }
    })
}

async function addPayment(type, partitionKey) {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/AddPayment/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: (type)
    }).then(res => {
        if (res.ok) {
            return true;
        } else {
            throw new Error();
        }
    })
}

async function removePayment(position, partitionKey) {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/RemovePayment/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: (position)
    }).then(res => {
        if (res.ok) {
            return true;
        } else {
            throw new Error();
        }
    })
}

async function CheckPipelineCompatibility(partitionKey) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/CheckPipelineCompatibility/" + partitionKey, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function CheckSchemaCompatibility(partitionKey) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/CheckSchemaCompatibility/" + partitionKey, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function uploadFile(file, partitionKey) {
    const accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/file/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: (file)
    }).then(res => {
        if (res.ok) {
            return res.json()
        } else {
            throw new Error();
        }
    })
}

async function getAllTenants() {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/admin/GetAllTenants", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
        else {
            return ""
        }
    }).then(res => {
        return res.split(',');
    })
}

async function getCurentTenant() {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/admin/curentTenantData", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
        else {
            return ""
        }
    }).then(res => {
        return res;
    })
}

async function switchCurentUserTenant(id) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/admin/SwitchCurentUserTenant", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: id
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res
    })
}

async function createNewClient(name) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/admin/CreateDb", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: name
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res
    })
}

async function getFormTypes(partitionKey) {
    const accessToken = await getAccessToken();
    
    return fetch(window.location.origin + "/api/v1.0/cosmos/formTypes/" + partitionKey, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return JSON.parse(res);
    })
}

async function addFormTypes(formType, partitionKey) {
    const accessToken = await getAccessToken();
    
    return fetch(window.location.origin + "/api/v1.0/cosmos/formTypes/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: formType
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return JSON.parse(res);
    })
}

async function patchFormTypes(formType, partitionKey) {
    const accessToken = await getAccessToken();
    
    return fetch(window.location.origin + "/api/v1.0/cosmos/formTypes/" + partitionKey, {
        method: "PATCH",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: formType
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return JSON.parse(res);
    })
}

async function moveFormsTypes(formType, partitionKey) {
    const accessToken = await getAccessToken();
    
    return fetch(window.location.origin + "/api/v1.0/cosmos/moveFormsTypes/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: formType
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function removeFormTypes(id, partitionKey) {
    const accessToken = await getAccessToken();
    
    return fetch(window.location.origin + "/api/v1.0/cosmos/formTypes/" + id + "/" + partitionKey, {
        method: "DELETE",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        if (res !== "ok") {
            return JSON.parse(res);
        }
        return res;
    })
}

async function CheckUnicityConflict(id, partitionKey) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/CheckUnicityConflict/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: id
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return JSON.parse(res)
    })
}

async function CheckUnicityConflictError(partitionKey) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/CheckUnicityConflict/" + partitionKey, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        if (res) {
            return JSON.parse(res)
        }
    })
}

async function UpdateFormVariables(variables, id, partitionKey) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/UpdateFormVariables/" + id + "/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: JSON.stringify(variables)
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res
    })
}

async function LogDesignerError(error, id, partitionKey) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/cosmos/LogDesignerError/" + id + "/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: JSON.stringify(error)
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res
    })
}

export {
    register,
    deleteUser,
    updateRights,
    updateDefaultRights,
    getUsers,
    getCurentUser,
    getUserFrom,
    tenantData,
    tenantLongData,
    newForm,
    formData,
    updateForm,
    updateInfoForm,
    updateDataForm,
    updateDesignerSteps,
    moveDesignerSteps,
    updateTheme,
    getHubspotKey,
    getHubspotKeyScopes,
    getHubspotKeyFO,
    getHubspotKeyScopesFO,
    updateHubspotKey,
    updateHubspotKeyFO,
    updateHubspotSkeleton,
    updateHubspotSkeletonById,
    updateHubspotSkeletonForced,
    updateHubspotPipeline,
    updateInformationList,
    updateInformationListGet,
    getPaymentKey,
    updatePaymentKey,
    addPayment,
    removePayment,
    getHubspotSkeleton,
    GenerateLightForms,
    updateFormStatus,
    CheckPipelineCompatibility,
    CheckSchemaCompatibility,
    uploadFile,
    getAllTenants,
    getCurentTenant,
    switchCurentUserTenant,
    createNewClient,
    getFormTypes,
    addFormTypes,
    patchFormTypes,
    moveFormsTypes,
    removeFormTypes,
    CheckUnicityConflict,
    CheckUnicityConflictError,
    UpdateFormVariables,
    LogDesignerError
}