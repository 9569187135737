import { getAccessToken } from "../../auth/verifyAuth";

async function getCandidature() {
    var accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/candidature", {
        method: "GET",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function getCandidatureById(id) {
    var accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/candidature/" + id, {
        method: "GET",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function createCandidature(candidature, partitionKey) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/CreateForm/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: JSON.stringify(candidature)
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    })
}


async function updateCandidature(candidature) {
    var accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/candidature/" + candidature.id, {
        method: "PATCH",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: JSON.stringify(candidature)
    })
}

export {
    getCandidature,
    getCandidatureById,
    createCandidature,
    updateCandidature
}