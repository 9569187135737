import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { onglets as settingsCards } from "./Settings/tabs";
import Status from "../components/Buttons/Status";
import CandidatureCard from "../components/Card/CandidatureCard";
import RowIconListItem from "../components/List/RowIconListItem";
import FakeList from "../components/Fake/FakeList";
import FakeCard from "../components/Fake/FakeCard";
import FilterBarCandidature from "./Home/FilterBarCandidature";
import { PlusIcon } from "@heroicons/react/solid";
import { CheckPipelineCompatibility, CheckSchemaCompatibility, CheckUnicityConflictError, GenerateLightForms, getCurentUser, getFormTypes, getUserFrom, tenantData } from "../utils/requests/cosmo";
import { useNavigate, useParams } from "react-router";
import { getUser, getAccessToken } from "../auth/verifyAuth";
import { toast } from "react-toastify";
import { checkActiveFormsUpToDate, getCheckPipenline, getPipelinesRuning, getStartPipeline } from "../utils/requests/appFrontOffice";
import { Tooltip } from "react-tooltip";

function Home({ t }) {
    const [loadingCandidature, setLoadingCandidature] = useState(true);
    const [candidatures, setCandidatures] = useState(null);
    const [filterList, setFilterList] = useState(null);
    const [displayList, setDisplayList] = useState(null);
    const [candidaturesfiltered, setCandidaturesFiltered] = useState(null);
    const [setup, setSetup] = useState(null);
    const [userName, setUserName] = useState(null);
    const [formPasted, setFormPasted] = useState(false);
    const [canCreateForm, setCanCreateFrom] = useState(false)
    const [userRights, setUserRights] = useState({})
    const [conflictErrorList, setConflictErrorList] = useState()
    const [pipelineLoading, setPipelineLoading] = useState(false);
    const [pipelineSucess, setPipelineSucess] = useState(false);
    const [nbrPipelinesRuning, setNbrPipelinesRuning] = useState(1);
    const [timeModal, setTimeModal] = useState(false);
    const [interval, setNewInterval] = useState(null);
    const [uptodate, setUptodate] = useState(false);
    const [key, setPartitionKey] = useState("");
    // const msalInstance = useContext(msalInstance);
    let { partitionKey } = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        setLoadingCandidature(true);
        setTimeModal(false);
        setUptodate(false);
        checkPipelineButton();
        setPipelineLoading(true);
        if (partitionKey) {
            setPartitionKey(partitionKey)
            initialising(partitionKey)
            getUserFrom(partitionKey).then(curentUser => {
                setUserRights(curentUser.rights)
                if (curentUser && curentUser.rights && curentUser.rights.create_forms === true) {
                    setCanCreateFrom(true)
                } else {
                    setCanCreateFrom(false)
                }
            })
        } else {
            getCurentUser().then(curentUser => {
                setPartitionKey(curentUser.partitionKey)
                setUserRights(curentUser.rights)
                // console.log(curentUser)
                if (curentUser && curentUser.rights && curentUser.rights.create_forms === true) {
                    setCanCreateFrom(true)
                } else {
                    setCanCreateFrom(false)
                }
                initialising(curentUser.partitionKey)
            })
        }
    }, [partitionKey])

    function initialising(locoKey) {
        CheckUnicityConflictError(locoKey).then(conflicts => {
            tenantData(locoKey).then(td => {
                // console.log(td);
                const userStatus = { "status": true }
                td.users = userStatus
                td.forms?.forEach(form => {
                    form?.Parameters?.forEach(param => {
                        form[param?.name] = param?.value
                    })
                })
                checkActiveFormsUpToDate(locoKey).then(res => {
                    if (res.length !== 0) {
                        let output = []
                        let formsObj = {}
                        for (let form of td.forms) {
                            formsObj[form.id] = form.name
                        }
                        for (let id of res) {
                            output.push(formsObj[id])
                        }
                        setUptodate(output)
                    }
                })
                if (conflicts) {
                    td.forms.forEach(form => {
                        if (conflicts.includes(form.id)) {
                            form.Error = true
                        }
                    });
                }
                getFormTypes(locoKey).then(formTypes => {
                    td.forms.forEach(form => {
                        form.partitionKey = locoKey
                    })
                    setCandidatures(td.forms);
                    // console.log(td.forms)
                    setCandidaturesFiltered(td.forms);
                    let filtersLocal = []
                    if (formTypes) {
                        let formTypeOptions = []
                        let count = 1
                        formTypes.forEach(formType => {
                            formTypeOptions.push({ value: formType.id, label: formType.name, name: formType.name, displayOrder: count})
                            count++
                        })
                        filtersLocal.push({ name: "formType", label: "Form type", type: "select", options: formTypeOptions, filter: true })
                    }
                    let displayLocal = []
                    td.hubspot?.information_list?.forEach(info => {
                        if (info.filter === true) {
                            filtersLocal.push(info)
                        }
                        if (info.display === true) {
                            displayLocal.push(info)
                        }
                    })
                    setFilterList(filtersLocal)
                    setDisplayList(displayLocal)
                    setSetup(td);
                    setLoadingCandidature(false);
                })
            })
        })
    }

    // function initialising() {
    //     setLoadingCandidature(true);
    //     tenantData(key).then(td => {
    //         setCandidatures(td.forms);
    //         setCandidaturesFiltered(td.forms);
    //         setSetup(td);
    //         setLoadingCandidature(false);
    //     })
    // }

    useEffect(() => {
        const userlocal = getUser();
        setUserName(userlocal["name"]);
    }, [])

    useEffect(() => {
        if (candidatures) {
            CheckPipelineCompatibility(key).then(errors => {
                var errTxt = "";
                var warnTxt = "";
                if (errors.length) {
                    candidatures.forEach(form => {
                        if (errors.includes(form.id)) {
                            if (form.enabled === true) {
                                if (errTxt === "") {
                                    errTxt = form.name;
                                } else {
                                    errTxt = errTxt + ", " + form.name;
                                }
                            }
                            else {
                                if (warnTxt === "") {
                                    warnTxt = form.name;
                                } else {
                                    warnTxt = warnTxt + ", " + form.name;
                                }
                            }
                        }
                    })
                    errTxt = errTxt.replace(new RegExp(",$"), t('and'))
                    warnTxt = warnTxt.replace(new RegExp(",$"), t('and'))
                    if (warnTxt !== "") {
                        toast.warn(warnTxt + t(" contain wrong pipelines"));
                    }
                    if (errTxt !== "") {
                        toast.error(errTxt + t(" contain wrong pipelines and is active. Please deactivate it or fix it."));
                    }
                }
            })
            CheckSchemaCompatibility(key).then(errors => {
                var errTxt = "";
                var warnTxt = "";
                if (errors.length) {
                    candidatures.forEach(form => {
                        if (errors.includes(form.id)) {
                            if (form.enabled === true) {
                                if (errTxt === "") {
                                    errTxt = form.name;
                                } else {
                                    errTxt = errTxt + ", " + form.name;
                                }
                            }
                            else {
                                if (warnTxt === "") {
                                    warnTxt = form.name;
                                } else {
                                    warnTxt = warnTxt + ", " + form.name;
                                }
                            }
                        }
                    })
                    errTxt = errTxt.replace(new RegExp(",$"), t('and'))
                    warnTxt = warnTxt.replace(new RegExp(",$"), t('and'))
                    if (warnTxt !== "") {
                        toast.warn(warnTxt + t(" contain wrong or old data"));
                    }
                    if (errTxt !== "") {
                        toast.error(errTxt + t(" contain wrong or old data and is active. Please deactivate it or fix it."));
                    }
                }
            })
        }
    }, [candidatures])

    function pasteForm() {
        if (!formPasted) {
            setFormPasted(true)
            navigator.clipboard.readText().then((clipText) => {
                try {
                    let jsonParsed = JSON.parse(clipText)
                    if (jsonParsed && typeof jsonParsed === "object") {
                        getAccessToken().then((accessToken) => {
                            fetch(window.location.origin + "/api/v1.0/cosmos/pasteForm", {
                                method: "POST",
                                headers: {
                                    "Accept": "application/json",
                                    "Content-Type": "application/json",
                                    "my-Authorization": `Bearer ${accessToken}`
                                },
                                body: clipText
                            // }).then(setTimeout(() => { GenerateLightForms().then(() => { setFormPasted(false); initialising() }) }, 2000))
                            }).then(res => {
                                if (res.ok) {
                                    return res.json();
                                }
                            }).then(res => {
                                navigate("/" + res + "/informations/" + key)
                                GenerateLightForms(key);
                            })
                        }
                        );
                    } else {
                        setFormPasted(false)
                    }
                }
                catch (e) {
                    setFormPasted(false)
                }
            });
        }
    }

    function rightCheck(rights) {
        let returnValue = true
        if (typeof rights === "string") {
            return !userRights[rights]
        } else {
            rights.forEach(right => {
                if (userRights[right]) {
                    returnValue = false
                }
            });
        }
        return returnValue
    }

    function startPipeline() {
        setUptodate(false);
        setPipelineLoading(true);
        getPipelinesRuning().then(res => {
            setNbrPipelinesRuning(res + 1)
            setTimeModal(true)
        })
        getStartPipeline(key)
        timer();
    }

    async function timer() {
        var myInterval = setInterval(() => {
            checkPipeline().then(res => {
                if (res === true) {
                    clearInterval(myInterval)
                }
            })
        }, 60000)
        return () => clearInterval(interval);
    }

    async function checkPipeline() {
        let loco = key
        if (!loco) {
            if (!partitionKey) {
                var usr = await getCurentUser()
                loco = usr.partitionKey
            } else {
                loco = partitionKey
            }
        }
        return await getCheckPipenline(loco).then(status_pipeline => {
            if (status_pipeline !== "inProgress") {
                setPipelineLoading(false);
                if (status_pipeline === "succeeded" || status_pipeline === "true") {
                    setPipelineSucess(true);
                } else {
                    setPipelineSucess(false);
                } return true;
            } else {
                setPipelineLoading(true);
                return false;
            }
        }).then(res => {
            return res;
        });
    }

    function checkPipelineButton() {
        setPipelineLoading(true);
        checkPipeline().then(res => {
            if (res === false) {
                timer();
            }
        })
    }

    return <>
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            {/* Main 3 column grid */}
            <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8 flex-wrap-reverse">
                {/* Left column */}
                <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                    {/* Welcome panel */}
                    {/* <section aria-labelledby="profile-overview-title">
                        <div className="rounded-lg bg-white overflow-hidden shadow">
                            <h2 className="sr-only" id="profile-overview-title">
                                {t("Welcome")}
                            </h2>
                            <div className="bg-white p-6">
                                <div className="sm:flex sm:items-center sm:justify-between">
                                    <div className="sm:flex sm:space-x-5">
                                        <div className="flex-shrink-0 flex justify-center">
                                        </div>
                                        <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                                            <p className="text-sm font-medium text-gray-600">{t("Welcome")} {userName}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}

                    {/* Actions panel */}
                    <section aria-labelledby="quick-links-title">
                        <div className="flex items-center px-2 mb-2">
                            <div className="flex-auto">
                                <h1 className="text-xl font-semibold text-gray-900">{t("Forms")}</h1>
                            </div>
                            <div className="flex mt-4 sm:mt-0 sm:ml-16 sm:flex-none items-stretch">
                                <button
                                    type="button"
                                    className={"inline-flex items-center justify-center rounded-md border mx-4 px-4 py-2 text-sm font-medium text-black shadow-sm bg-white enabled:hover:bg-slate-200 enabled:focus:outline-none enabled:focus:ring-2 enabled:focus:ring-blue-500 enabled:focus:ring-offset-2 sm:w-auto disabled:opacity-50" + (formPasted ? " cursor-wait	opacity-50" : "")}
                                    onClick={() => { pasteForm() }}
                                    disabled={!canCreateForm}
                                >
                                    {t("Paste form")}
                                </button>
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm enabled:hover:bg-blue-700 enabled:focus:outline-none enabled:focus:ring-2 enabled:focus:ring-blue-500 enabled:focus:ring-offset-2 sm:w-auto disabled:opacity-50"
                                    onClick={() => navigate("/new/informations/" + key)}
                                    disabled={!canCreateForm}
                                >
                                    {t("Add form")}
                                </button>
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center rounded-md border ml-4 border-transparent bg-primary-600 px-4 text-sm font-medium text-white shadow-sm enabled:hover:bg-primary-700 enabled:focus:outline-none enabled:focus:ring-2 enabled:focus:ring-primary-500 enabled:focus:ring-offset-2 sm:w-auto disabled:opacity-50"
                                    onClick={() => !pipelineLoading && startPipeline()}
                                    disabled={!canCreateForm}
                                    data-tooltip-id="DetailsTooltip" data-tooltip-html={!uptodate ? null : "Some active forms are not up to date anymore :<br/>" + uptodate.join("<br/>")}
                                >
                                    <div className="h-6 w-6">
                                        <Status loading={pipelineLoading} success={pipelineSucess} warning={uptodate} />
                                    </div>
                                    {t("Forms")}
                                </button>
                                {/* <div className="inline-flex">
                                    <div className="h-6 w-6" data-tooltip-id="DetailsTooltip" data-tooltip-content={!uptodate ? null : "Some active forms are not up to date anymore"}>
                                        <Status loading={pipelineLoading} success={pipelineSucess} warning={uptodate} />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <FilterBarCandidature candidatures={candidatures} setFilteredCandidatures={setCandidaturesFiltered} filterList={filterList} />
                        <div className="mt-1 rounded-lg bg-gray-200 overflow-hidden shadow ">
                            <h2 className="sr-only" id="quick-links-title">
                                {t("Candidatures")}
                            </h2>
                            {loadingCandidature ? <FakeList length={3} component={FakeCard} /> : (candidaturesfiltered && candidaturesfiltered.length ?
                                <div className="overflow-y-auto overflow-x-hidden divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px" style={{maxHeight:"70vh"}}>
                                    {candidaturesfiltered.map((candidature, candidatureIndex) => (
                                        <CandidatureCard key={candidature.id} candidature={candidature} candidatureId={candidatureIndex} amount={candidaturesfiltered.length} displayList={displayList} />
                                    ))}
                                </div> : <div className="text-center w-full col-span-2 py-8">
                                    <svg
                                        className="mx-auto h-12 w-12 text-gray-400"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            vectorEffect="non-scaling-stroke"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                                        />
                                    </svg>
                                    <h3 className="mt-2 text-sm font-medium text-gray-900">{t("No forms")}</h3>
                                    <p className="mt-1 text-sm text-gray-500">{t("Get started by creating a new form.")}</p>
                                    <div className="mt-6">
                                        <button
                                            type="button"
                                            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                                            onClick={() => navigate("/new/informations/" + key)}
                                        >
                                            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                            {t("New Form")}
                                        </button>
                                    </div>
                                </div>)}
                        </div>
                    </section>
                </div>

                {/* Right column */}
                <div className="grid grid-cols-1 gap-4 hidden lg:block">
                    <section>
                        <div className="rounded-lg bg-white overflow-hidden shadow">
                            <div className="p-6">
                                <h2 className="text-base font-medium text-gray-900" id="settings">
                                    {t("Settings")}
                                </h2>
                                <div className="flow-root mt-6">
                                    <ul className="-my-5 divide-y divide-gray-200">
                                        {settingsCards.filter(c => c.href !== null).map((setting) => (
                                            <li key={setting.href} className="py-4">
                                                <RowIconListItem icon={<><img className="h-8 w-8"
                                                    src={process.env.PUBLIC_URL + "/logo/" + setting.href + ".png"}
                                                    alt="logo"
                                                />
                                                    <div className="bg-white rounded-md absolute block h-4 w-4" style={{ right: "-7px", bottom: "-6px" }}>
                                                        <Status success={setup && setup?.[setting.id]?.status === true} />
                                                    </div></>}
                                                    title={t(setting.name)}
                                                    link={partitionKey ? "/settings/" + setting.href + "/" + partitionKey : "/settings/" + setting.href}
                                                    buttonLabel={t("View")}
                                                    buttondeactivate={setting.right ? rightCheck(setting.right) : false} />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <button onClick={() => { CheckSchemaCompatibility() }}>test</button> */}
                </div>
            </div>
        </div>
        <Tooltip id="DetailsTooltip" effect="solid" place="bottom-end" variant="info" />
    </>

}

export default withTranslation()(Home);