import { getAccessToken } from "../../auth/verifyAuth";

async function UpdateStaticWebApp(partitionKey) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/azureManagement/UpdateStaticWebApp/" + partitionKey, {
        method: "GET",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.statusText;
        }
    }).then(res => {
        return res;
    })
}

async function CreateNewPipeline(partitionKey) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/azureManagement/CreatePipeline/" + partitionKey, {
        method: "GET",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.statusText;
        }
    }).then(res => {
        return res;
    })
}

async function postPipelineOptions(partitionKey) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/azureManagement/PostPipelineProperties/" + partitionKey, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function getStartPipeline(partitionKey) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/azureManagement/StartPipeline/" + partitionKey, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function getCheckPipenline(partitionKey) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/azureManagement/CheckPipeline/" + partitionKey, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            if (res.status === 204) {
                return null;
            }
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function getPipelinesRuning() {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/azureManagement/GetPipelinesRuning", {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            if (res.status === 204) {
                return null;
            }
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function postAuthorityData(authority, partitionKey) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/azureManagement/PostAuthorityData/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: authority
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function postDeploymentData(jsonData, partitionKey) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/azureManagement/PostDeploymentData/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: JSON.stringify(jsonData)
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function getDeploymentDataStatus(partitionKey) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/azureManagement/GetDeploymentDataStatus/" + partitionKey, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function getAllHostnamesStatus(partitionKey) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/azureManagement/GetAllHostnamesStatus/" + partitionKey, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        if (res) {
            res = res.replaceAll('[','')
            let resSplit = res.split(']')
            resSplit.forEach((t, i) => {
                resSplit[i] = t.split(", ")
            })
            return resSplit;
        }
        return res;
    })
}

async function getDeploymentData(partitionKey) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/azureManagement/GetDeploymentData/" + partitionKey, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

async function postDomainName(name, partitionKey) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/azureManagement/PostDomainName/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: (name)
        // body: (id  + '\\' + name)
    }).then(res => {
        if (res.ok) {
            return res.json()
        }
    })
}


async function getDefaultHostname(partitionKey) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/azureManagement/GetDefaultHostname/" + partitionKey, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json()
        }
    })
}

async function GetHostnames(partitionKey) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/azureManagement/GetHostnames/" + partitionKey, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json()
        }
    })
}


async function getDomainNameStatus(name, partitionKey) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/azureManagement/GetDomainNameStatus/" + partitionKey, {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        },
        body: (name)
    }).then(res => {
        if (res.ok) {
            return res.json()
        }
    }).then(res => {
        return res
    })
}

async function updateDeploymentStatus(partitionKey) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/azureManagement/UpdateStatus/" + partitionKey, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.arrayBuffer;
        }
    }).then(res => {
        return res;
    })
}

async function checkActiveFormsUpToDate(partitionKey) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/cosmos/CheckActiveFormsUpToDate/" + partitionKey, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return res;
    })
}

export {
    UpdateStaticWebApp,
    CreateNewPipeline,
    postPipelineOptions,
    getStartPipeline,
    getCheckPipenline,
    getPipelinesRuning,
    postAuthorityData,
    postDeploymentData,
    getDeploymentDataStatus,
    getAllHostnamesStatus,
    getDeploymentData,
    postDomainName,
    getDefaultHostname,
    GetHostnames,
    getDomainNameStatus,
    updateDeploymentStatus,
    checkActiveFormsUpToDate
}