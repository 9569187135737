import classNames from "classnames";
import { useEffect, useState, useMemo, useRef } from "react";
import Xarrow from "react-xarrows";
import { withTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';
import { Tooltip } from 'react-tooltip';
import Content from './../../../../components/DragNDrop/Content';
import Details from "./Details";

function Logic({ t, candidature, handleOnChange, changeStep }) {
    const [layout, setLayout] = useState([]);
    const [step, setStep] = useState(0); //TODO bouton de séléction de step a faire
    const [stepsObj, setStepsObj] = useState({})
    const [subsList, setSubsList] = useState([])
    const [totem, setTotem] = useState({ inception: 0 })
    const [selected, setSelected] = useState(null)
    const [stepsObjDetail, setStepsObjDetail] = useState({})
    const [fromLogic, setFromLogic] = useState(true)

    const event = new Event("resize")

    useEffect(() => {
        if (candidature) {
            initialisation()
        }
    }, [candidature, step])

    function initialisation() {
        window.dispatchEvent(event)
        setStep(changeStep(undefined))
        setTotem({ inception: 0 })
        let curentStep = candidature.Step[step].questions
        let localstepsObj = {}
        let localLayout = []
        curentStep.forEach(s => { localstepsObj[s.id] = s })
        let subsL = {}
        curentStep.filter(s => s.sub).forEach(s => { subsL[s.sub] = 0 })
        setSubsList(subsL)
        setStepsObj(localstepsObj)
        curentStep.forEach(s => {
            localLayout = localLayout.concat(conditionalParsingMain(s, localstepsObj))
        })
        setLayout(localLayout)
        let localDetail = {}
        Object.keys(localstepsObj).forEach(lso => {
            let tmplso = JSON.parse(JSON.stringify(localstepsObj[lso]))
            tmplso.data = JSON.parse(JSON.stringify(localstepsObj[lso]))
            localDetail[lso] = tmplso
        })
        setStepsObjDetail(localDetail)
    }

    function ifDisplay(condition) {
        let cond = ""
        if (condition) {
            if (stepsObj[condition.block]) {
                cond = (stepsObj[condition.block] && stepsObj[condition.block].label) + " " + condition.select + " " + (typeof condition.cond === typeof [] ? condition.cond.join(", ") : condition.cond)
            }
            else {
                cond = stepsObj[stepsObj[condition.subs[0]].sub].label + " " + condition.select + " " + (typeof condition.cond === typeof [] ? condition.cond.join(", ") : condition.cond)
            }
            if (condition.And) {
                for (let c = condition.And; c.And; c = c.And) {
                    if (c) {
                        cond = cond + " " + t("and") + " " + (stepsObj[c.block] && stepsObj[c.block].label) + " " + c.select + " " + (typeof c.cond === typeof [] ? c.cond.join(", ") : c.cond)
                    }
                }
            }
            return (t("if") + " " + cond + " " + t("then show") + " " + (condition.subs.every(e => e.label !== null) && condition.subs.map(e => stepsObj[e].label).join(", ")))
        }
    }

    function conditionalParsingMain(question, objs) {
        let localLayout = []
        if (!question.sub) {
            localLayout.push(question)
            if (question.conditionalList) {
                localLayout.push(conditionalParsingSecondary(question, objs))
            }
        }
        return localLayout
    }

    function conditionalParsingSecondary(question, objs) {
        let localLayout = []
        if (question.conditionalList) {
            question.conditionalList.forEach(condition => {
                let subLayout = []
                condition.subs.forEach(sub => {
                    let subsubs = []
                    if (!("multiple" in objs[sub])) {
                        objs[sub]["multiple"] = 0
                    } else {
                        objs[sub]["multiple"] += 1
                    }
                    const tmpJson = JSON.parse(JSON.stringify(objs[sub]))
                    tmpJson.id = tmpJson.id + ";" + tmpJson["multiple"]
                    if (objs[sub].conditionalList) {
                        subsubs.push(tmpJson)
                        subsubs = subsubs.concat(conditionalParsingSecondary(objs[sub], objs))//recursion
                    } else {
                        subsubs = tmpJson
                    }
                    subLayout.push(subsubs)
                })
                localLayout.push(subLayout)
            })
        }
        return localLayout
    }

    function conditionalDisplay(component) {
        if (component instanceof Array) {
            subsList[component.flat()[0].sub] = subsList[component.flat()[0].sub] + 1
            if (isTree(component)) {
                return (
                    <>
                        <div className="flex gap-x-5 border border-black my-auto py-2 mx-10" id={"box-" + component.flat()[0].sub + "-" + subsList[component.flat()[0].sub]} key={uuidv4()}>
                            <div className="w-40 h-20 border border-black text-ellipsis overflow-hidden my-auto bg-white mx-5 z-0" id={component[0].id} key={uuidv4()}>{component[0].conditional ? <div key={uuidv4()}>{component[0].label}<div></div><button key={uuidv4()} onClick={() => setSelected(component[0].id)} className="w-6 h-6 p-auto border border-black text-center hover:bg-slate-200">+</button></div> : component[0].label}</div>
                            <div>
                                {component.slice(1).map((single, z) => {
                                    if (single instanceof Array) {
                                        return conditionalDisplay(single)
                                    } else {
                                        return <div className="w-40 h-20 border border-black text-ellipsis overflow-hidden my-auto bg-white mx-5 z-0" id={single.id} key={uuidv4()}>{single.conditional ? <div key={uuidv4()}>{single.label}<div></div><button key={uuidv4()} onClick={() => setSelected(single.id)} className="w-6 h-6 p-auto border border-black text-center hover:bg-slate-200">+</button></div> : single.label}</div>
                                    }
                                }
                                )}
                            </div>
                        </div></>
                )
            }
            return (<>
                <div className="flex gap-x-5 border border-black my-auto py-2 mx-10" id={"box-" + component.flat()[0].sub + "-" + subsList[component.flat()[0].sub]} key={uuidv4()}>
                    {component.map((single, z) => {
                        if (single instanceof Array) {
                            return conditionalDisplay(single)
                        } else {
                            return <div className="w-40 h-20 border border-black text-ellipsis overflow-hidden my-auto bg-white mx-5 z-20" id={single.id} key={uuidv4()}>{single.conditional ? <div key={uuidv4()}>{single.label}<div></div><button key={uuidv4()} onClick={() => setSelected(single.id)} className="w-6 h-6 p-auto border border-black text-center hover:bg-slate-200">+</button></div> : single.label}</div>
                        }
                    }
                    )}
                </div></>)
        } else {
            return (<div className="w-40 h-20 border border-black text-ellipsis overflow-hidden my-auto bg-white z-0" id={component.id} key={uuidv4()}>{component.conditional ? <div key={uuidv4()}>{component.label}<div></div><button key={uuidv4()} onClick={() => setSelected(component.id)} className="w-6 h-6 p-auto border border-black text-center hover:bg-slate-200">+</button></div> : component.label}</div>)
        }
    }

    function condListCounterFunc(id, counter) {
        if (counter[id] + 1 >= stepsObj[id].conditionalList.length) {
            counter[id] = 0
        } else {
            counter[id]++
        }
        return
    }

    function arrowDisplay() {
        // console.log(layout)
        // console.log("stepCount: ")
        // console.log(JSON.parse(JSON.stringify(stepCount)))
        // console.log(totem)
        if (layout) {
            let result = arrowDisplayMain(layout).flat(99)
            // console.log("stepCountArrow: ")
            // console.log(JSON.parse(JSON.stringify(stepCountArrow)))
            // console.log(JSON.parse(JSON.stringify(stepCountArrowPrev)))
            // console.log(result)
            result = [...new Set(result)]
            totem.inception += 1
            let conditionalListCounter = {}
            result.forEach(r => conditionalListCounter[r.split(':')[0].split(";")[0]] = 0)
            return (<>{result.map(r => (
                <Xarrow start={r.split(':')[0]} end={r.split(':')[1]} startAnchor="right" endAnchor="left" key={uuidv4()} color="black" strokeWidth={1} showTail={true} tailShape="circle" tailSize={5} zIndex={0} labels={(stepsObj[r.split(':')[0].split(";")[0]].conditional ? (
                    <div key={uuidv4()}>
                        <button key={uuidv4()} className="w-6 h-6 p-auto border border-black bg-white rounded-full text-center hover:bg-slate-200 z-10" onClick={(b) => { setFromLogic(b.target.id.split(':').pop()); setSelected(r.split(':')[0].split(";")[0]) }} id={r + ":" + conditionalListCounter[r.split(':')[0].split(";")[0]]} data-tooltip-id="my-tooltip" data-tooltip-content={ifDisplay(stepsObj[r.split(':')[0].split(";")[0]].conditionalList[conditionalListCounter[r.split(':')[0].split(";")[0]]])}>X</button>
                        {/* <Tooltip anchorSelect={"#" + r + "tooltip"} effect="solid" type="info" content={ifDisplay(stepsObj[r.split(':')[0].split(";")[0]].conditionalList[conditionalListCounter[r.split(':')[0].split(";")[0]]])}/> */}
                        {condListCounterFunc(r.split(':')[0].split(";")[0], conditionalListCounter)}
                    </div>
                ) : "")} />
            ))}</>)
        }
    }

    function arrowDisplayMain(layout) {
        let output = []
        // console.log(layout)
        if (layout instanceof Array) {
            layout.forEach((block, index) => {
                if (index < layout.length - 1) {
                    if (!(block instanceof Array)) {
                        if (!(layout[index + 1] instanceof Array)) {
                            // console.log(11)
                            output.push(block.id + ":" + layout[index + 1].id)
                        } else if (isTree(layout)) {
                            layout.forEach((b, i) => {
                                if (i !== 0) {
                                    // console.log(111)
                                    output.push(layout[0].id + ":" + [b].flat(99)[0].id)
                                    output = output.concat(arrowDisplayMain(b))
                                }
                            })
                            return output
                        } else {
                            layout[index + 1].forEach((subs, y) => {
                                if (subs instanceof Array) {
                                    // console.log(12)
                                    output.push(block.id + ":" + subs.flat(99)[0].id)
                                    if (subs.length > 1) {
                                        // console.log(-13)
                                        output = output.concat(arrowDisplayMain(subs))
                                    } else if (subs[0] instanceof Array) {
                                        // console.log(-14)
                                        output = output.concat(arrowDisplayMain(subs[0]))
                                    }
                                } else {
                                    if (y === 0) {
                                        // console.log(15)
                                        output.push(block.id + ":" + subs.id)
                                    } else {
                                        // console.log(16)
                                        output.push(layout[index + 1][y - 1].id + ":" + subs.id)
                                    }
                                }
                            })
                        }
                    } else {
                        block.forEach((subs, y) => {
                            if (subs instanceof Array) {
                                // console.log(21)
                                // console.log(layout)
                                // console.log(subs)
                                if (subs.length === 1 && isTree(subs[0])) {
                                    subs[0].slice(1).forEach(sub => {
                                        output.push([sub].flat(99).pop().id + ":" + [layout[index + 1]].flat(99)[0].id)
                                    })
                                } else {
                                    output.push([subs].flat(99).pop().id + ":" + [layout[index + 1]].flat(99)[0].id)
                                }
                                if (subs.length > 1) {
                                    // console.log(-22)
                                    output = output.concat(arrowDisplayMain(subs))
                                }
                            }
                            else {
                                if (y === block.length) {
                                    // console.log(23)
                                    output.push(subs.id + ":" + layout[index + 1].id)
                                } else if (block.length > 1 && block[y + 1]) {
                                    // console.log(24)
                                    output.push(subs.id + ":" + [block[y + 1]].flat(99)[0].id)
                                }
                            }
                        })
                    }
                } else {
                    if (block instanceof Array && block.length > 1) {
                        // console.log(-5)
                        output = output.concat(arrowDisplayMain(block))
                    }
                }
            })
        }
        return output
    }

    function isTree(data) {
        let output = true
        if (data instanceof Array && data.length > 2 && !(data[0] instanceof Array)) {
            data.slice(1).forEach(solo => {
                if ([solo].flat(99)[0] && [solo].flat(99)[0].sub !== data[0].id.split(";")[0]) {
                    output = false
                }
            })
            return output
        }
        return false
    }

    return (candidature && layout && layout.length) ? <div className="w-full h-full bg-slate-200 absolute left-0 overflow-auto" style={{ height: "inherit" }}>
        <div className="h-10 min-w-10 w-fit bg-white m-auto fixed inset-x-0 top-[116px] rounded-b-lg z-40 opacity-50 hover:opacity-100">
            {candidature.Step.map((s, i) => (<button key={uuidv4()} className={"border m-2 hover:bg-slate-300" + (step === i ? " bg-slate-200" : "")} onClick={() => { setStep(i); changeStep(i) }}>{s.name}</button>))}
        </div>
        <div className="min-h-full min-w-full h-fit w-fit flex" key={uuidv4()}>
            {layout.map((v, i) => (
                <div className="flex flex-col" key={uuidv4()}>
                    {(v instanceof Array) ?
                        v.map((s, y) => conditionalDisplay(s)) :
                        <div className="w-40 h-20 border border-black text-ellipsis	overflow-hidden my-auto mx-10 bg-white z-0" id={v.id} key={uuidv4()}>{v.conditional ? <div key={uuidv4()}>{v.label}<div></div><button key={uuidv4()} onClick={() => setSelected(v.id)} className="w-6 h-6 p-auto border border-black text-center hover:bg-slate-200">+</button></div> : v.label}</div>
                    }
                </div>
            ))}
            {arrowDisplay()}
        </div>
        {selected &&
            <div className=" fixed left-0 top-0 w-1/3 mt-[116px] z-40" style={{ height: "inherit", maxHeight: "-webkit-fill-available" }}>
                <Details componentsAll={stepsObjDetail} id={stepsObjDetail[selected.split(';')[0]].id} handleOnChange={(r) => { handleOnChange(r); setSelected(null); initialisation(); setFromLogic(true) }}
                    onClose={() => { setSelected(null); setFromLogic(true) }} setRefresh={() => { }} setSelectedSubs={() => { }} fromLogic={fromLogic} candidature={candidature} />
            </div>
        }
        <Tooltip id="my-tooltip" style={{ zIndex: 50, maxWidth: "30em" }} variant="info" />
    </div>
        : null
}

export default withTranslation()(Logic);