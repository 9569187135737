import React, { lazy, Suspense } from 'react';
import FakeCard from '../../components/Fake/FakeCard';
import FakeList from '../../components/Fake/FakeList';
import FakeText from '../../components/Fake/FakeText';
import FormsTypes from './Tabs/FormsTypes';
const GlobalTheme = lazy(() => import('./Tabs/GlobalTheme'));
const Hubspot = lazy(() => import('./Tabs/Hubspot'));
const Payment = lazy(() => import('./Tabs/Payment'));
const ApplicationFrontOffice = lazy(() => import("./Tabs/ApplicationFrontOffice"));
const Users = lazy(() => import("./Tabs/Users"));

const onglets = [
    {
        name: 'Home',
        href: null
    },
    {
        name: 'Default theme',
        href: 'GlobalTheme',
        component: GlobalTheme,
        id: "theme",
        right: "theme"
    },
    {
        name: 'Hubspot',
        href: 'Hubspot',
        component: Hubspot,
        id: "hubspot",
        right: "app_settings"
    },
    {
        name: 'Payment',
        href: 'Payment',
        component: Payment,
        id: "payment",
        right: "app_settings"
    },
    {
        name: 'FrontOffice Application',
        href: 'frontoffice',
        component: ApplicationFrontOffice,
        id: "deployment",
        right: ["app_settings", "publish_forms"]
    },
    {
        name: 'Forms Types',
        href: 'formstypes',
        component: FormsTypes,
        id: "formstypes",
        right: ["app_settings"]
    },
    {
        name: 'Users',
        href: 'Users',
        component: Users,
        id: "users",
        right: ["add_users", "add_rights"]
    }
]

const renderTabPanel = (onglet) => {
    var currentPageParams = onglets.find(ong => ong.href === onglet);

    if (currentPageParams && currentPageParams.component) {
        var Component = currentPageParams.component;
        return (
            <Suspense fallback={<div><FakeList length={3} component={FakeCard} /></div>}>
                <Component />
            </Suspense>
        );
    } else {
        // redirect 404
    }

};



export {
    onglets,
    renderTabPanel
}