import { withTranslation } from "react-i18next";
import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Input from "../Inputs/Input";
import 'react-json-view-lite/dist/index.css';
import { v4 as uuidv4 } from 'uuid';
import { set } from "react-hook-form";
import Checkbox from "../Inputs/Checkbox";

const types = ["text", "number", "date", "booleancheckbox", "checkbox", "price"]

function VariablesPopup({ t, id, setId, cancel, variables, saveVariables, removeVariable }) {
    const [open, setOpen] = useState(true)
    const [type, setType] = useState("")
    const [name, setName] = useState("")
    const [value, setValue] = useState("")

    useEffect(() => {
        if (open === true) {
            if (id === "new") {
                setType("")
                setName("")
                setValue("")
            } else {
                const variable = variables.find(v => v.id === id)
                setType(variable.fieldType)
                setName(variable.name)
                setValue(variable.value)
            }
        }
    }, [id, open]);

    const cancelButtonRef = useRef(null)
    const erraseButtonRef = useRef(null)

    useEffect(() => {
        if (open === false) {
            cancel()
        }
    }, [open, cancel])

    function save() {
        console.log(type, name, value)
        if (id === "new") {
            const newid = uuidv4()
            setId(newid)
            saveVariables([...variables, { id: newid, fieldType: type, name: name, value: value }])
        } else {
            variables[variables.findIndex(v => v.id === id)] = { id: id, fieldType: type, name: name, value: value }
            saveVariables(variables)
        }
        setOpen(false)
    }

    function remove() {
        removeVariable(id)
        setOpen(false)
    }


    function renderSwitch() {
        switch (type) {
            case "text":
                return (
                    <input
                        id="value"
                        label={t("Value")}
                        placeholder={t("Value")}
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        className="pl-2 block w-full text-sm rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                    />);
            case "number":
                return (
                    <input
                        id="value"
                        type="number"
                        step="1"
                        label={t("Value")}
                        placeholder={t("Value")}
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        className="pl-2 block w-full text-sm rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                    />);
            case "price":
                return (
                    <input
                        id="value"
                        type="number"
                        step="1"
                        label={t("Value")}
                        placeholder={t("Value")}
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        className="pl-2 block w-full text-sm rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                    />);
            case "date":
                return (
                    <input
                        id="value"
                        type="date"
                        label={t("Value")}
                        placeholder={t("Value")}
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        className="pl-2 block w-full text-sm rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                    />);
            case "booleancheckbox":
            case "checkbox":
                return (
                    <div className="pl-2 flex block w-full text-sm py-1.5 text-gray-900 placeholder:text-gray-400 sm:text-sm/6">
                        {value === "true" ?
                            <label htmlFor="valueTrue" className="pr-2 w-12">
                                {t("True")}
                            </label>
                            :
                            <label htmlFor="valueFalse" className="pr-2 w-12">
                                {t("False")}
                            </label>
                        }
                        <Checkbox classname="my-auto mx-2 place-self-center hover:accent-slate-600 h-4 w-4" value={value === "true"} onClick={() => value === "true" ? setValue("false") : setValue("true")} />
                    </div>
                );
            default:
                return (null)
        }
    }


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                    <div className="">
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                                {t("Variable")}
                                            </Dialog.Title>
                                        </div>
                                        <div className="mt-3 mx-2">
                                            <label htmlFor="type" className="block text-sm font-medium text-gray-900 py-2">
                                                {t("Type")}
                                            </label>
                                            <select
                                                id="type"
                                                label={t("Type")}
                                                value={type}
                                                onChange={e => { (e.target.value !== type && setValue("")); setType(e.target.value) }}
                                                className="pl-2 block w-full text-sm rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                            >
                                                <option id={"empty"} key={"empty"} value={""}></option>
                                                {types.map((type) => (
                                                    <option id={type} key={type} value={type}>{t(type)}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mt-3 mx-2">
                                            <label htmlFor="name" className="block text-sm font-medium text-gray-900 py-2">
                                                {t("Name")}
                                            </label>
                                            <input
                                                id="name"
                                                label={t("Name")}
                                                placeholder={t("Name")}
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                className="pl-2 block w-full text-sm rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                            />
                                        </div>
                                        <div className="mt-3 mx-2">
                                            <label htmlFor="value" className="block text-sm font-medium text-gray-900 py-2">
                                                {t("Value")}
                                            </label>
                                            {renderSwitch()}
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 sm:ml-6 sm:w-auto"
                                        onClick={() => { save() }}
                                    >
                                        {t("Save")}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:ml-6"
                                        onClick={() => { setOpen(false); cancel() }}
                                        ref={cancelButtonRef}
                                    >
                                        {t("Cancel")}
                                    </button>
                                    {id !== "new" && <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-500 sm:mt-0 sm:w-auto sm:ml-6"
                                        onClick={() => { setOpen(false); remove() }}
                                        ref={erraseButtonRef}
                                    >
                                        {t("Delete")}
                                    </button>}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default withTranslation()(VariablesPopup);