import { getAccessToken } from "../../auth/verifyAuth";

async function newTemplate(partitionKey) {
    const accessToken = await getAccessToken();

    return fetch(window.location.origin + "/api/v1.0/template/CreateTemplate/" + partitionKey, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "my-Authorization": `Bearer ${accessToken}`
        }
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
    }).then(res => {
        return JSON.parse(res);
    })
}


export {
    newTemplate
}